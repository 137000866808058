<template>
    <div class="container limit-mid agradecimento">
        <h1>Agradecemos o contato, em breve retornaremos!
            <a href="https://evahc.com.br" class="btn-contato">
                Voltar à página inicial
            </a>
        </h1>

    </div>
</template>
<script>
import gsap from 'gsap'
export default {
    mounted() {
        setTimeout(() => {
            gsap.to('.agradecimento h1', {
                opacity: 1,
                duration: 1
            })
        }, 1000)
        //setTimeout(()=>{
        //    window.location.href="https://evahc.com.br"
        //},4500)
    }
}
</script>

<style lang="scss">
.agradecimento {
    h1 {
        opacity: 0;

        a {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            margin-top: 20px;
            color: var(--preto);
            background-color: var(--branco);
            border: 1px solid var(--preto);
            border-radius: 50px;
            font-size: 18px;
            font-weight: 500;
            width: fit-content !important;
            padding: 8px 35px;
            transition: 0.3s;
        }

        a:hover {
            color: var(--branco);
            background-color: var(--preto);
        }
    }
}
</style>