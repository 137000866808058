<template>
    <div class="intro-view">
        <h2 class="text-intro"><span>Evahc Studio<sup>©</sup></span></h2>
    </div>
</template>

<script>
import gsap from 'gsap'
export default {

    mounted() {
        const iview = ".intro-view";
        const tl = gsap.timeline();
        gsap.set(iview, { x: 0 })
        gsap.set(".text-intro", {
            opacity: 0,
            x: -50,
        })


        gsap.to(iview, {
            x: 0,
            duration: 1,
            ease: "power4.out",
        })

        tl.to(".text-intro", {
            x: 0,
            opacity: 1,
            delay: 0.5,
            ease: "linear",
            duration: 1,
        })
        tl.to(".text-intro", {
            x: 25,
            opacity: 0,
            ease: "linear",
            duration: 0.5,
            filter: " blur(10px)",
        })
        gsap.to(iview, {
            x: "120vw",
            delay: 2,
            duration: 1,
            filter: " blur(12px)",
            ease: "power4.out"
        })



    }

}
</script>

<style lang="scss">
.intro-view {
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--preto);
    z-index: 1002;
    color: var(--branco);

    h2 {
        font-family: var(--overused) !important;
        font-size: 48px;
        text-align: center;
        font-weight: 400;

        span sup {
            font-size: 25px;
        }


    }

    display: flex;
    align-items: center;
    justify-content: center;
}
</style>